import React, { useState, useEffect, createContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import Main from './components/Main.js';
import Services from './components/Services.js';
import Reviews from './components/Reviews.js';
import NavigationTest from './components/NavigationTest';
import EmailContent from './components/EmailContent';
import Admin from './components/Admin';
import Offers from './components/Offers';
import './styles/Global.css';

const DatesContext = createContext();
const TimeSlotsContext = createContext();

function App() {
  const [dates, setDates] = useState([]);
  // const [timeSlots, setTimeSlots] = useState([]);

  useEffect(() => {
    // Fetch your dates data here
    // For example:
    fetch('https://razwebdev.com/lashes/api/dates')
      .then((response) => response.json())
      .then((json) => setDates(json.data))
      .catch((err) => console.log(err.message));

    // Fetch your time slots data here
    // For example:
    // fetch('https://razwebdev.com/lashes/api/timeslots')
    //   .then((response) => response.json())
    //   .then((json) => setTimeSlots(json.data))
    //   .catch((err) => console.log(err.message));
  }, []);

  return (
    <div className="App">
      <NavigationTest />
   
      <DatesContext.Provider value={dates}>
        {/* <TimeSlotsContext.Provider value={timeSlots}> */}
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/services" element={<Services />} />
            {/* <Route path="/reviews" element={<Reviews />} /> */}
            <Route path="/email" element={<EmailContent />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/offers" element={<Offers />} />
          </Routes>
        {/* </TimeSlotsContext.Provider> */}
      </DatesContext.Provider>
    </div>
  );
}

export default App;
export { DatesContext, TimeSlotsContext };
