import React, { useState } from 'react';
import '../styles/Offers.css';
import homephoto from '../images/homephoto.jpg';
import BookingModal from './BookingModal.js';


function Offers() {
    const [isModalOpen, setIsModalOpen] = useState(true);

    return (
        <div>
        <div className="offers-div">
            <h2>Our Special Offers</h2>
            <p><strong>Note: </strong>The services should be booked separately (at different times) by each friend, however mention in the "comments" section of the booking form the name of the friend/s you are sharing the offer with.</p>

            <p>1. Bring a friend and enjoy a 15% discount for both of you!</p>
            <p>2. Bring 2 friends and enjoy a 20% discount for all 3 of you.</p>


            {/* 
            <p style={{fontSize:"1rem"}}><strong>Note:</strong> For offers 2. and 3. the services should be booked separately (at different times) by each friend, however mention in the "comments" section of the booking form the name of the friend/s you are sharing the offer with.</p>
            <p>1. Loyal clients receive a 35% discount on their birthday lash set! (This means you need to have had at least 6 full set appointments with LuxLashes to qualify.)</p>

            <p>2. Bring a friend and enjoy a 15% discount for both of you!</p>

            <p>3. Bring 2 friends and enjoy a 20% discount for all 3 of you.</p>

            <p>4. Your 7th full set with LuxLashes will be 50% discount!</p>

            <p>5. Post your lashes on your story and tag us @lux.lashes.leeuwarden to get a 10% discount on your next set!</p> */}
            
        </div>
        <div style={{ textAlign: 'center', backgroundColor: "black", height: '5rem', position:'fixed', bottom:'0', width:'100%' }}>
                {/* <p style={{ color: "white", paddingTop: "1rem" }}>Copyright</p> */}
            </div>

            {isModalOpen && <BookingModal/>}

        </div>


    )
}
export default Offers;