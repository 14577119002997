import '../styles/EmailContent.css';


function EmailContent() {
    return (
        <div className="container">
            <h1>Booking Confirmation</h1>
            <div className="booking-info">
                <h3>Hello Name,</h3>
                {/* <p><strong>Name:</strong> test test</p> */}
                <p>This is an automatic email sent to you to confirm your booking with Lux Lashes Leeuwarden with the following details:</p>
                <p><strong>Selected Service:</strong> test</p>
                <p><strong>Total Price: </strong>€40</p>
                <p><strong>Comments:</strong> test</p>
                <p><strong>Silent Treatment:</strong> test</p>

                <div className='contact-details'>
                    <span className='contact-details-p'>Contact Details</span>
                    <p><strong>Email:</strong> test</p>
                    <p><strong>Phone:</strong> test</p>
                </div>
                <div className='ddp'>
                    <div className='date-div'>
                        <p><strong>Date</strong></p>
                        <p>test, test test at test</p>
                    </div>

                    <div className='duration-div'>
                        <p><strong>Duration</strong></p>
                        <p>test minutes</p>
                    </div>

                </div>
                
            </div>
        </div>

    )
}
export default EmailContent;