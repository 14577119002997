import "../styles/Admin.css";
import React, { useState, useEffect } from "react";
import { Buffer } from "buffer";
import AdminLashSets from "./AdminLashSets";

function Admin() {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [loginStatus, setLoginStatus] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [selectStatus, setSelectStatus] = useState("all");
  const [searchName, setSearchName] = useState("");
  const [services, setServices] = useState([]);
  const [dates, setDates] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [isBookingsVisible, setIsBookingsVisible] = useState(false);
  const [isLashSetsVisible, setIsLashSetsVisible] = useState(false);
  const [datesIndex, setDatesIndex] = useState(0);
  const [canceledOrCompleted, setCanceledOrCompleted] = useState(false);
  const [updatedSlot, setUpdatedSlot] = useState(false);

  useEffect(() => {
    // Check if the URL contains the scrollTo parameter
    if (localStorage.getItem("token")) setIsLoggedIn(true);

    fetch("https://razwebdev.com/lashes/api/timeslots")
      .then((response) => response.json())
      .then((json) => {
        setTimeSlots(json.data);
      })
      .catch((err) => {
        console.log(err.message);
      });

    fetch("https://razwebdev.com/lashes/api/bookings2")
      .then((response) => response.json())
      .then((json) => {
        setBookings(json.data);
      })
      .catch((err) => {
        console.log(err.message);
      });

    fetch("https://razwebdev.com/lashes/api/dates")
      .then((response) => response.json())
      .then((json) => {
        setDates(json.data);
      })
      .catch((err) => {
        console.log(err.message);
      });

    fetch("https://razwebdev.com/lashes/api/lashsets2")
      .then((response) => response.json())
      .then((json) => {
        setServices(json.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const encodedString = Buffer.from(
      formData.username + ":" + formData.password
    ).toString("base64");

    fetch("https://razwebdev.com/lashes/api/auth", {
      method: "POST",
      headers: new Headers({ Authorization: "Basic " + encodedString }),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json.message != "good good")
          setLoginStatus("Sorry, " + json.message);
        else {
          localStorage.setItem("token", json.data.token);
          setIsLoggedIn(true);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const logOut = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("token");
  };

  const updateSlot = (slot) => {
    var updated;
    if (slot.available === "no") updated = "yes";
    else updated = "no";

    const formData = new FormData();
    formData.append("available", updated);
    formData.append("id", slot.id);

    fetch("https://razwebdev.com/lashes/api/updatetimeslotadmin", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((json) => {
        console.log(json);
        setUpdatedSlot(!updatedSlot);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  useEffect(() => {
    fetch("https://razwebdev.com/lashes/api/timeslots")
      .then((response) => response.json())
      .then((json) => {
        setTimeSlots(json.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [updatedSlot]);

  const markCanceled = (id, status) => {
    setCanceledOrCompleted(!canceledOrCompleted);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("status", status);

    fetch("https://razwebdev.com/lashes/api/updatebookingstatus", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((json) => {
        console.log(json);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const markComplete = (id, status) => {
    setCanceledOrCompleted(!canceledOrCompleted);
    console.log(id);
    console.log(status);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("status", status);

    fetch("https://razwebdev.com/lashes/api/updatebookingstatus", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((json) => {
        console.log(json);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  useEffect(() => {
    // Check if the URL contains the scrollTo parameter

    fetch("https://razwebdev.com/lashes/api/bookings2")
      .then((response) => response.json())
      .then((json) => {
        setBookings(json.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [canceledOrCompleted]);

  const selectBooking = (value) => {
    if (selectStatus !== "all") return value.status === selectStatus;
    return selectStatus === "all";
  };

  const searchBooking = (value, searchName) => {
    if (searchName != "") {
      return (
        value.firstName.toLowerCase().includes(searchName.toLowerCase()) ||
        value.lastName.toLowerCase().includes(searchName.toLowerCase()) ||
        (value.firstName + " " + value.lastName)
          .toLowerCase()
          .includes(searchName.toLowerCase()) ||
        (value.lastName + " " + value.firstName)
          .toLowerCase()
          .includes(searchName.toLowerCase()) ||
        value.date.toLowerCase().includes(searchName.toLowerCase())
      );
    } else {
      return true; // Return true if searchName is empty to include all values
    }
  };

  const onSelect = (status) => setSelectStatus(status);

  const onSearch = (event) => setSearchName(event.target.value);

  const displayBookings = bookings
    .sort((a, b) => b.id - a.id)
    .filter(selectBooking)
    .filter((booking) => searchBooking(booking, searchName))
    .map((booking) => {
      const filteredServices = services.filter((service) => {
        return service.id === booking.lashSetId;
      });
      const displayService = filteredServices.map((service) => {
        return (
          <span key={service.id}>
            {service.name === "Removal" && <span>{service.name}</span>}
            {booking.removal === "yes" && service.name !== "Removal" && (
              <span>
                {service.name}, {service.description} + Lash Removal
              </span>
            )}
            {booking.removal === "no" && (
              <span>
                {service.name}, {service.description}
              </span>
            )}
          </span>
        );
      });

      return (
        <div key={booking.id} className="bookings-admin">
          <div className="booking-info">
            <p>ID: {booking.id}</p>
            <p>Service: {displayService}</p>
            <p>
              Date and Time: {booking.date} at {booking.time}
            </p>
            <p>Instagram: {booking.instagram}</p>
            <p>Comments: {booking.comments}</p>
          </div>

          <div className="booking-details">
            <p>
              Name: {booking.firstName} {booking.lastName}
            </p>
            <p>Email: {booking.email}</p>
            <p>Phone: {booking.phone}</p>
            <p>Status: {booking.status}</p>
          </div>
          <div>
            <button
              style={{ width: "90px", marginTop: "3rem" }}
              onClick={() => markComplete(booking.id, "completed")}
            >
              Complete Booking
            </button>
            <button
              style={{ width: "100px", marginTop: "3rem" }}
              onClick={() => markCanceled(booking.id, "canceled")}
            >
              Cancel Booking
            </button>
          </div>
        </div>
      );
    });

  const [selectedDates, setSelectedDates] = useState([]);

  // Get the current date
  const today = new Date();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const todayDay = daysOfWeek[today.getDay()].toLowerCase();
  const todayDayNumber = today.getDate().toString();
  const todayMonth = months[today.getMonth()].toLowerCase();
  const todayYear = today.getFullYear().toString();
  // console.log(todayDay)
  // console.log(todayDayNumber)
  // console.log(todayMonth)
  // console.log(todayYear)

  const todayIndex = dates.findIndex((date) => {
    const dateDay = date.daytext.toLowerCase();
    const dateDayNumber = date.daynumber;
    const dateMonth = date.month.toLowerCase();
    const dateYear = date.year.toString();

    return (
      dateDay === todayDay &&
      dateDayNumber === todayDayNumber &&
      dateMonth === todayMonth &&
      dateYear === todayYear
    );
  });

  const filteredDates = dates.slice(todayIndex + 1);

  const displayDates = filteredDates
    .slice(datesIndex, datesIndex + 30)
    .map((date) => {
      const filteredSlots = timeSlots.filter((slot) => {
        return slot.date === date.id;
      });

      // Create a state variable to keep track of the selected date(s)

      // Define a function to toggle the visibility of time slots for a specific date
      const toggleTimeSlots = (dateId) => {
        if (selectedDates.includes(dateId)) {
          // If the clicked date is already selected, remove it
          setSelectedDates(selectedDates.filter((id) => id !== dateId));
        } else {
          // Otherwise, add it to the selected dates
          setSelectedDates([...selectedDates, dateId]);
        }
      };

      const displayTimeSlots = selectedDates.includes(date.id)
        ? filteredSlots.map((slot) => (
            <div
              key={slot.id}
              className={`slot-admin ${
                slot.available === "no" ? "unavailable" : ""
              }`}
              onClick={() => updateSlot(slot)}
            >
              <p>{slot.time}</p>
            </div>
          ))
        : null;

      return (
        <div key={date.id} className="dates-admin">
          <p onClick={() => toggleTimeSlots(date.id)}>
            {date.daytext} {date.daynumber} {date.month} {date.year}
          </p>
          <div className="slots-admin">{displayTimeSlots}</div>
        </div>
      );
    });

  const viewBookings = () => {
    setIsCalendarVisible(false);
    setIsBookingsVisible(true);
    setIsLashSetsVisible(false);
  };

  const viewCalendar = () => {
    setIsCalendarVisible(true);
    setIsBookingsVisible(false);
    setIsLashSetsVisible(false);
  };

  const viewLashSets = () => {
    setIsLashSetsVisible(true);
    setIsCalendarVisible(false);
    setIsBookingsVisible(false);
  };
  const backDates = () => {
    if (datesIndex !== 0) setDatesIndex(datesIndex - 30);
  };
  const forwardDates = () => {
    if (datesIndex < dates.length - 27) setDatesIndex(datesIndex + 30);
    console.log(datesIndex);
  };

  const emailContent2 = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Booking Confirmation</title>
    </head>
    <body style="font-family: Arial, sans-serif; background-color: #f4f4f4; color: black; margin: 0; padding: 0;">
      <div style="max-width: 600px; margin: 0 auto; background-color: #ffffff; padding: 20px; border-radius: 5px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); text-align: left;">
        <p>hau bau test test</p>
      </div>
    </body>
    </html>
    `;

  const confirmationMail = () => {
    const formdata = new FormData();
    formdata.append("emailContent", emailContent2);
    console.log("sending email");
    setTimeout(function () {
      fetch("https://razwebdev.com/lashes/api/confirmation", {
        method: "POST",
        body: formdata,
      })
        .then((response) => response.json())
        .then((json) => {})
        .catch((err) => {
          console.log(err.message);
        });
      // Your code here
    }, 86400000);
  };

  return (
    <div>
      {!isLoggedIn && (
        <div>
          <h2>Admin Login</h2>

          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                alignItems: "flex-start",
                width: "80%",
                margin: "0 auto",
                maxWidth: "500px",
                marginTop: "20px",
              }}
            >
              <label htmlFor="username">Username:</label>
              <input
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                style={{ width: "100%" }}
                required
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                alignItems: "flex-start",
                width: "80%",
                margin: "0 auto",
                maxWidth: "500px",
                marginTop: "20px",
              }}
            >
              <label htmlFor="password">Password</label>
              <input
                type="text"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                style={{ width: "100%" }}
              />
            </div>
            <button type="submit" style={{ width: "100px", marginTop: "3rem" }}>
              Submit
            </button>
            <p>{loginStatus}</p>
          </form>
        </div>
      )}

      {isLoggedIn && (
        <div style={{ textAlign: "center", margin: "0 auto" }}>
          <h2>Clau's Admin Page</h2>

          <br />
          <button
            style={{ width: "100px", marginTop: "3rem" }}
            onClick={confirmationMail}
          >
            Test Email
          </button>
          <br />

          <button
            style={{ width: "100px", marginTop: "3rem" }}
            onClick={logOut}
          >
            Log out
          </button>
          <div>
            <button
              style={{ width: "100px", marginTop: "3rem" }}
              onClick={viewBookings}
            >
              View Bookings
            </button>
            <button
              style={{ width: "100px", marginTop: "3rem" }}
              onClick={viewCalendar}
            >
              View Calendar
            </button>
            <button
              style={{ width: "100px", marginTop: "3rem" }}
              onClick={viewLashSets}
            >
              View Lash Sets
            </button>
          </div>
          {isBookingsVisible && (
            <div>
              <button
                style={{ width: "100px", marginTop: "3rem" }}
                onClick={() => onSelect("all")}
              >
                All
              </button>
              <button
                style={{ width: "100px", marginTop: "3rem" }}
                onClick={() => onSelect("completed")}
              >
                Completed
              </button>
              <button
                style={{ width: "100px", marginTop: "3rem" }}
                onClick={() => onSelect("pending")}
              >
                Pending
              </button>
              <button
                style={{ width: "100px", marginTop: "3rem" }}
                onClick={() => onSelect("canceled")}
              >
                Canceled
              </button>
              <br />
              <input
                type="text"
                placeholder="search date or client"
                value={searchName}
                onChange={onSearch}
                style={{
                  width: "15rem",
                  marginTop: "2rem",
                  marginBottom: "2rem",
                }}
              ></input>
              {displayBookings.length !== 0 && displayBookings}
              {displayBookings.length === 0 && (
                <p>No bookings with status {selectStatus}</p>
              )}
            </div>
          )}

          {isCalendarVisible && (
            <div>
              {displayDates}
              <div>
                <button
                  style={{ width: "100px", marginTop: "3rem" }}
                  onClick={backDates}
                >
                  Back
                </button>
                <button
                  style={{ width: "100px", marginTop: "3rem" }}
                  onClick={forwardDates}
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {isLashSetsVisible && <AdminLashSets />}
        </div>
      )}
    </div>
  );
}

export default Admin;
