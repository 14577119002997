import '../styles/Contact.css';


function Contact() {
  
    const daysOfWeek = [
        { day: 'BUSINESS HOURS' },
        { day: 'Monday' },
        { day: 'Tuesday' },
        { day: 'Wednesday' },
        { day: 'Thursday' },
        { day: 'Friday' },
        { day: 'Saturday' },
        { day: 'Sunday' },
    ];

    const week1 = [
        { day: 'This Week' },
        { day: '10:00-23:00' },
        { day: '10:00-23:00' },
        { day: '10:00-23:00' },
        { day: '10:00-23:00' },
        { day: '10:00-23:00' },
        { day: '10:00-22:00' },
        { day: '10:00-22:00' },
    ];

    const week2 = [
        { day: 'Next Week' },
        { day: '10:00-23:00' },
        { day: '10:00-23:00' },
        { day: '10:00-23:00' },
        { day: '10:00-23:00' },
        { day: '10:00-23:00' },
        { day: '10:00-22:00' },
        { day: '10:00-22:00' },
    ];
    return (
        <div className='contact-div' >
            <div className='schedule' id='schedule'>

                <div className='days'>
                    {daysOfWeek.map((day, index) => (
                        <div key={index} className='day-div'>

                            <span className='p-day' style={{ fontWeight: index === 0 ? '600' : 'normal' }}>
                                {day.day}
                            </span>

                        </div>
                    ))}
                </div>

                <div className='week1'>
                    {week1.map((day, index) => (
                        <div key={index} className='day-div'>
                            <span className='p-day' style={{ fontWeight: index === 0 ? '600' : 'normal' }}>
                                {day.day}
                            </span>
                        </div>
                    ))}
                </div>

                {/* <div className='week2'>
                    {week2.map((day, index) => (
                        <div key={index} className='day-div'>

                            <span className='p-day' style={{ fontWeight: index === 0 ? '600' : 'normal' }}>
                                {day.day}
                            </span>
                        </div>
                    ))}
                </div> */}
            </div>

            <div className='contact' id='contact' >
                <p style={{ fontWeight: "600" }}>CONTACT</p>
               <span> Leeuwarden</span>
               <span> luxlashesleeuwarden@gmail.com</span>
               <span> Call:+31 617 392 889 </span>
               <span> WhatsApp:+40 722 593 496 </span>
               <span> Instagram: @lux.lashes.leeuwarden </span>
            </div>
        </div>
    )

}
export default Contact;