import React, { useState, useEffect, useContext } from 'react';
import '../styles/Calendar.css';
import { DatesContext, TimeSlotsContext } from '../App'; // Import the context


function Calendar(props) {
  const [selectedDate, setSelectedDate] = useState(props.selectedDateFromModal);
  const [filteredTimes, setFilteredTimes] = useState([]);
  const [timeSlots, setTimeSlots] = useState(props.timeSlots)




  const dates = useContext(DatesContext); // Access the dates from context
  // const timeSlots = useContext(TimeSlotsContext); // Access the dates from context


  // Function to scroll the calendar forward
  const scrollForward = () => {

    let scrollContainer = document.querySelector(".dates");
    scrollContainer.style.scrollBehavior = "smooth";
    scrollContainer.scrollLeft += 392;
  };

  // Function to scroll the calendar backward
  const scrollBackward = () => {
    let scrollContainer = document.querySelector(".dates");
    scrollContainer.style.scrollBehavior = "smooth";
    scrollContainer.scrollLeft -= 392; // Scroll left by 455 pixels
  };

  // Get the current date
  const today = new Date();
const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const todayDay = daysOfWeek[today.getDay()].toLowerCase();
const todayDayNumber = today.getDate().toString();
const todayMonth = months[today.getMonth()].toLowerCase();
const todayYear = today.getFullYear().toString();
// console.log(todayDay)
// console.log(todayDayNumber)
// console.log(todayMonth)
// console.log(todayYear)


const todayIndex = dates.findIndex((date) => {
  const dateDay = date.daytext.toLowerCase();
  const dateDayNumber = date.daynumber;
  const dateMonth = date.month.toLowerCase();
  const dateYear = date.year.toString();
  
  return (
    dateDay === todayDay &&
    dateDayNumber === todayDayNumber &&
    dateMonth === todayMonth &&
    dateYear === todayYear
  );
});




  const handleDateClick = (date, isFirstDate) => {
    if(isFirstDate)
    {
      return
    }
    const filteredTimeSlots=timeSlots.filter(slot =>{
      return slot.date===date.id && slot.available==="yes"
    })
    if(filteredTimeSlots.length!==0)
    {
      setSelectedDate(date);
      // setTimeSlotsLoading(true);
      const filteredDates = dates.filter(apidate => {
        return apidate.id === date.id;
      })
  
      props.mainDateSelected(date);
      setFilteredTimes(timeSlots.filter(slot=>{
        return slot.date===filteredDates[0].id;
      }))
      
    }
  };





  const displayTimes = filteredTimes.map(time => {
    return (
      <div
        key={time.id}
        className={`time-div ${time.available === "yes" ? 'available' : 'unavailable'}`}
        onClick={() => handleTimeSlotClick(time)}
      >
        {time.time}
      </div>
    )
  })


  // const filteredDates=dates.filter(date =>{
  //   return (date.daynumber>=today.getDate())
  // })
  const filteredDates = dates.slice(todayIndex);

  
  const displayDates = filteredDates.slice(0, 90).map((date, index) => {
    const filteredTimeSlots = timeSlots.filter((slot) => {
      return slot.date === date.id && slot.available === "yes";
    });
    
    var isSelected
    if(selectedDate!==null)
    isSelected = selectedDate.id === date.id;
    const isFirstDate = index === 0;

    return (
      <span
        key={date.id}
        className={`individual-date ${
          isSelected ? 'selected' : ''} ${filteredTimeSlots.length === 0 || isFirstDate ? 'unavailable' : ''} `}
        onClick={() => handleDateClick(date, isFirstDate)}
      >
        <span className='date-and-month'>{date.daytext.slice(0, 3)}</span>
        <span className='day'>{date.daynumber}</span>
        <span className='date-and-month'>{date.month.slice(0, 3)}</span>
      </span>
    );
  });
  
  




  const handleTimeSlotClick = (time) => {
    // setSelectedTime(time);
    if (time.available !== "no") {
      props.bookingModalTimeSelected(time);
      // console.log(time.id)
    }
  };
  return (
    <div className="calendar">
      <div className="dates">
       {displayDates}

        <p onClick={scrollBackward} className='navigation-button-back'>&lt;</p>
        <p onClick={scrollForward} className='navigation-button-forwards'>&gt;</p>
      </div>
      {selectedDate &&
        <div className="times">
          <div className='times-list'>
            {displayTimes}

          </div>
        </div>}

    </div>
  );
}

export default Calendar;
