import '../styles/Home.css';
import homephoto from '../images/homephoto.jpg';
import homephoto2 from '../images/homephoto2.jpeg';
import gift from '../images/gift.svg';
import { useNavigate } from 'react-router-dom';


function Home() {

    const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to the desired location when the button is clicked
    navigate('/offers');
  };
    return (
        <div className='home' id='home'>
        <div className='home-div'>
      <img src={gift} alt="gift" className='gift' />
      <button className='offers-p' onClick={handleClick}>Our Special Offers</button>
        <p style={{marginTop:'20px', fontSize:'1.2rem', textAlign:'center'}}>Let your eyes speak their story with Lux Lashes</p>

        {/* ✨  */}

            <img src={homephoto2} alt="Logo" className='homephoto' />
        </div>
        <br/>

        <br/>

        </div>
    )
}
export default Home;