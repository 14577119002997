import React, { useState, useEffect } from 'react';

function AdminLashSets() {
    const [services, setServices] = useState([]);
    const [categories, setCategories] = useState([]);

    const [loading, setLoading] = useState(true);
    const [selectedService, setSelectedService] = useState(null);
    const [selectedServiceId, setSelectedServiceId] = useState(null);
    const [selectedServiceName, setSelectedServiceName] = useState('');
    const [selectedServiceDuration, setSelectedServiceDuration] = useState('');
    const [selectedServiceCategory, setSelectedServiceCategory] = useState('');
    const [selectedServicePrice, setSelectedServicePrice] = useState('');
    const [showNewSetInterface, setShowNewSetInterface] = useState(false);

    const [newServiceName, setNewServiceName] = useState('');
    const [newServiceDuration, setNewServiceDuration] = useState('');
    const [newServicePrice, setNewServicePrice] = useState('');
    const [newServiceCategory, setNewServiceCategory] = useState('');


    useEffect(() => {
        // Fetch services data
        fetch('https://razwebdev.com/lashes/api/lashsets2')
            .then((response) => response.json())
            .then((json) => {
                setServices(json.data);
                const uniqueCategories = Array.from(new Set(json.data.map(service => service.category)));
                setCategories(uniqueCategories);                
                setLoading(false);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    const handleServiceClick = (serviceId) => {
        setSelectedService(serviceId === selectedService ? null : serviceId);
    
        // Find the selected service
        const selectedServiceObject = services.find(service => service.id === serviceId);
        if (selectedServiceObject) {
            // Update state variables with the selected service details
            setSelectedServiceId(selectedServiceObject.id);
            setSelectedServiceName(selectedServiceObject.name);
            setSelectedServiceCategory(selectedServiceObject.category);
            setSelectedServiceDuration(selectedServiceObject.duration);
            setSelectedServicePrice(selectedServiceObject.price);
        }
    };
    

    const handleCancel = () => {
        setSelectedService(null);
    };


    const handleUpdate = () =>{
    console.log(selectedServiceId, selectedServiceName, selectedServiceCategory, selectedServiceDuration, selectedServicePrice);

    const formData = new FormData();
        formData.append("id", selectedServiceId);
        formData.append("name", selectedServiceName);
        formData.append("category", selectedServiceCategory);
        formData.append("duration", selectedServiceDuration);
        formData.append("price", selectedServicePrice);

        fetch("https://razwebdev.com/lashes/api/updatelashset",
            {
                method: 'POST',
                body: formData
            })
            .then(
                (response) => response.text()
            )
            .then(
                (json) => {
                    console.log(json);
                    fetch('https://razwebdev.com/lashes/api/lashsets2')
            .then((response) => response.json())
            .then((json) => {
                setServices(json.data);
                alert("updated");
                // setLoading(false);
            })
            .catch((err) => {
                console.log(err.message);
            });
                })
            .catch(
                (e) => {
                    console.log(e.message)
                })

    }

    const handleDeleteLashSet =()=>{
        console.log("Remove lash set with id: "+selectedServiceId);
        const formData = new FormData();
        formData.append("id", selectedServiceId);

        fetch("https://razwebdev.com/lashes/api/deletelashset",
            {
                method: 'POST',
                body: formData
            })
            .then(
                (response) => response.text()
            )
            .then(
                (json) => {
                    console.log(json);
            fetch('https://razwebdev.com/lashes/api/lashsets2')
            .then((response) => response.json())
            .then((json) => {
                setServices(json.data);
                alert("removed set");
                window.location.reload();

                // setLoading(false);
            })
            .catch((err) => {
                console.log(err.message);
            });
                })
            .catch(
                (e) => {
                    console.log(e.message)
                })
    }

    const renderServiceDetails = () => {
        return (
            <div>
                <div><strong>ID: </strong>{selectedServiceId}</div>
                <div><strong>Name: </strong><input type="text" value={selectedServiceName} onChange={(e) => setSelectedServiceName(e.target.value)} /></div>
                {/* <div><strong>Category: </strong>{selectedServiceCategory}</div> */}
                <div><strong>Duration: </strong><input type="text" value={selectedServiceDuration} onChange={(e) => setSelectedServiceDuration(e.target.value)} /></div>
                <div><strong>Price: </strong><input type="text" value={selectedServicePrice} onChange={(e) => setSelectedServicePrice(e.target.value)} /></div>
                {/* Render other details as needed */}
                <button style={{marginRight:'1rem', marginTop:'1rem'}} onClick={handleUpdate}>Update</button>
                <button style={{marginLeft:'1rem', marginTop:'1rem'}} onClick={handleCancel}>Cancel</button>
                <br/>
                <button style={{marginTop:'2rem'}} onClick={handleDeleteLashSet}>Remove Lash Set</button>

            </div>
        );
    };
    
    

    const displayServices = services.map((service) => (
        <div key={service.id} 
            style={{ backgroundColor: 'rgb(248, 246, 246)', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%', height:'4rem' }} 
            onClick={() => handleServiceClick(service.id)}>
            <span style={{ width: '20%' }}>{service.name}</span>
            {/* <span style={{ width: '20%' }}>{service.description}</span> */}
            <span style={{ width: '20%' }}>{service.category}</span>
            <span style={{ width: '20%' }}>{service.duration}</span>
            <p style={{ width: '20%', textAlign: 'center', margin: '0' }}>{service.price} €</p>
        </div>
    ));

    const addSetClick =()=>{
        setShowNewSetInterface(true);
    }

    const backToServices =()=>{
        setShowNewSetInterface(false);
        setNewServiceCategory('');
        setNewServiceDuration('');
        setNewServicePrice('');
        setNewServiceName   ('');

    }

    const handleCategoryChange =(event)=>{
        setNewServiceCategory(event.target.value);
    }

    const handleAddNewSetSubmit =()=>{
        if (newServiceCategory!='' && newServicePrice !== '' && newServiceDuration !== '' && newServiceName !== '') {
            // console.log('Category:', newServiceCategory);
            // console.log('Price:', newServicePrice);
            // console.log('Duration:', newServiceDuration);
            // console.log('Name:', newServiceName);
            // alert("lash set added");        
            // window.location.reload();

            // Add your further logic here

            const formData = new FormData();
            formData.append("name", newServiceName);
            formData.append("category", newServiceCategory);
            formData.append("duration", newServiceDuration);
            formData.append("price", newServicePrice);
    
            fetch("https://razwebdev.com/lashes/api/insertlashset",
                {
                    method: 'POST',
                    body: formData
                })
                .then(
                    (response) => response.text()
                )
                .then(
                    (json) => {
                        console.log(json);
                fetch('https://razwebdev.com/lashes/api/lashsets2')
                .then((response) => response.json())
                .then((json) => {
                    setServices(json.data);
                    alert("added new set");
                    window.location.reload();

                    // setLoading(false);
                })
                .catch((err) => {
                    console.log(err.message);
                });
                    })
                .catch(
                    (e) => {
                        console.log(e.message)
                    })
        }
        else
            alert("please complete all fields");        
            }
    const newSetInterface =(
         
            <div>
                <div><strong>Name: </strong><input type="text" value={newServiceName} onChange={(e) => setNewServiceName(e.target.value)} /></div>
                {/* <div><strong>Category: </strong>{selectedServiceCategory}</div> */}
                <div><strong>Category</strong> <br/>
                <select style={{ width: "100%", height: "3rem", border: "none", backgroundColor: '#edebeb', borderBottom: "2px solid #cccaca", borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}               value={newServiceCategory} onChange={handleCategoryChange}>
                <option value=''>Select a category...</option>
                {categories.map((category, index) => (
                    <option key={index} value={category}>{category}</option>
                ))}
            </select></div> 
                <div><strong>Duration: </strong><input type="text" value={newServiceDuration} onChange={(e) => setNewServiceDuration(e.target.value)} /></div>
                <div><strong>Price: </strong><input type="text" value={newServicePrice} onChange={(e) => setNewServicePrice(e.target.value)} /></div>
                <div style={{marginTop:"1rem"}}>
            <button
            style={{marginRight:"1rem"}}
            onClick={handleAddNewSetSubmit}
            >
                Add
                
            </button>
            <button
            style={{marginLeft:"1rem"}}
            onClick={backToServices}
            >
                Cancel
                
            </button>
            </div>
            </div>
        
    )

    return (
        <div style={{ paddingTop: "1rem", width: '90%', margin: '0 auto', paddingBottom:'5rem' }}>
            <h3 style={{ textAlign: 'center' }}>Lash Sets</h3>
            {!showNewSetInterface && 
            <button
            style={{marginTop:"1rem", marginBottom:"2rem"}}
            onClick={addSetClick}
            >
                Add Set
                
            </button>
            }
            {showNewSetInterface && newSetInterface}
            {showNewSetInterface
            }

            {!showNewSetInterface && !selectedService && displayServices}
            {selectedService && renderServiceDetails()}
            
        </div>
    );
}

export default AdminLashSets;
