import React, { useState, useEffect } from 'react';
import '../styles/NavigationTest.css';
import logo from '../images/logolashes.jpeg';


function NavigationTest() {
    const pageUp = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const [showMobileNav, setShowMobileNav] = useState(false);

    

    useEffect(() => {
        // Check if the URL contains the scrollTo parameter
        const params = new URLSearchParams(window.location.search);

        if (params.get('to') === 'contact') {
            setTimeout(() => {
                // Scroll to the #contact section
                const contactSection = document.getElementById('contact');
                if (contactSection) {
                    contactSection.scrollIntoView({ behavior: 'smooth' });
                }
            }, 200); // Adjust the delay as needed
        }

        if (params.get('to') === 'schedule') {
            setTimeout(() => {
                // Scroll to the #contact section
                const contactSection = document.getElementById('schedule');
                if (contactSection) {
                    contactSection.scrollIntoView({ behavior: 'smooth' });
                }
            }, 200); // Adjust the delay as needed
        }
    }, []);

    const handleContactClick = () => {
        // Check if the current URL contains "/services"
        if (window.location.pathname.includes('/services') || window.location.pathname.includes('/reviews') || window.location.pathname.includes('/offers')) {
            // If it does, navigate to "/?to=contact"
            window.location.href = '/?to=contact';
        } else {
            // If it doesn't, navigate to "#contact"
            window.location.href = '#contact';
        }
    };

    const handleScheduleClick = () => {
        // Check if the current URL contains "/services"
        if (window.location.pathname.includes('/services') || window.location.pathname.includes('/reviews') || window.location.pathname.includes('/offers')) {
            // If it does, navigate to "/?to=contact"
            window.location.href = '/?to=schedule';
        } else {
            // If it doesn't, navigate to "#contact"
            window.location.href = '#schedule';
        }
    };

    return (

        <div className="navbar">
            <div className="nav-container">


                <img className='logo' src={logo}></img>
                <li><a href="/">Home</a></li>
                <li><a href="/services">Prices</a></li>
                {/* <li><a href="/reviews">Reviews</a></li> */}
                <li><a href="/offers">Our Special Offers</a></li>
                <li><a onClick={handleScheduleClick} >Bussines Hours</a></li>
                <li><a onClick={handleContactClick}>Contact</a></li>

            </div>

            <input id="toggle" type="checkbox" onClick={()=>setShowMobileNav(!showMobileNav)}></input>

            <label for="toggle" className="hamburger">
                <div className="top-bun"></div>
                <div className="meat"></div>
                <div className="bottom-bun"></div>
            </label>

       
               {showMobileNav &&  <div className="menu-items">
                    <li><a href="/">Home</a></li>
                    <li><a href="/services">Prices</a></li>
                    {/* <li><a href="/reviews">Reviews</a></li> */}
                    <li><a href="/offers">Our Special Offers</a></li>
                    <li><a onClick={handleScheduleClick} >Bussines Hours</a></li>
                    <li><a onClick={handleContactClick}>Contact</a></li>
                </div>
}
       
        </div>

    );
}

export default NavigationTest;
